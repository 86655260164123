<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">代理商</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<!-- <a-form-model-item label="用户类型"> -->
				<!-- <a-select placeholder="请选择" v-model="query.type">
						<a-select-option :value="0">
							全部
						</a-select-option>
						<a-select-option :value="1">
							代理商
						</a-select-option>
						<a-select-option :value="2">
							医院
						</a-select-option>
						<a-select-option :value="3">
							护士
						</a-select-option>
						<a-select-option :value="4">
							运营
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="所属医院">
					<a-select placeholder="请选择" v-model="query.hospital_id">
						<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item> -->
				<a-form-model-item label="用户姓名">
					<a-input v-model="query.name" placeholder="输入用户姓名" />
				</a-form-model-item>
				<a-form-model-item label="联系电话">
					<a-input v-model="query.phone" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="创建人">
					<a-input v-model="query.create_name" placeholder="请输入" />
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style=" margin-left: 10px">+新增</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="stop_using" slot-scope="text, record">
				<a-space>
					{{ record.stop_using == 1 ? '正常' : '停用' }}
				</a-space>
			</span>

			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
					<a-popconfirm title="确认停用该账户" ok-text="是" cancel-text="否" @confirm="stop(record.account_id)"
						v-if="record.stop_using == 1">
						<a style="color: #E0352B">停用</a>
					</a-popconfirm>
					<a-popconfirm title="确认停用该账户" ok-text="是" cancel-text="否" @confirm="stop(record.account_id)"
						v-if="record.stop_using != 1">
						<a style="color: #2086e6">恢复</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>
		<template>
			<a-modal :title="(account_id ? '编辑' : '新增') + '账户'" :visible="visible" ok-text="提交" cancel-text="取消"
				:confirm-loading="confirmLoading" @ok="submitInfo" @cancel="handleCancel">
				<a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="equipmentForm" :model="form"
					:rules="info_rules">
					<!-- <a-form-model-item label="账户类型" prop="type" v-if="!account_id">
						<a-select placeholder="请选择" v-model="form.type">
							<a-select-option :value="1">
								代理商
							</a-select-option>
							<a-select-option :value="2">
								医院
							</a-select-option>
							<a-select-option :value="3">
								护士
							</a-select-option>
							<a-select-option :value="4">
								运营
							</a-select-option>
						</a-select>
					</a-form-model-item> -->
					<a-form-model-item prop="name" label="姓名">
						<a-input v-model="form.name"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="phone" label="联系方式">
						<a-input v-model="form.phone"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="remark" label="备注">
						<a-textarea v-model="form.remark"></a-textarea>
					</a-form-model-item>
					<!-- <a-form-model-item label="医院">
						<a-select placeholder="请选择" v-model="form.hospital_id" @change="hospitalChange">
							<a-select-option :value="item.hospital_id" v-for="item in hospitals" :key="item.hospital_id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="科室">
						<a-select placeholder="请选择" v-model="form.department_id">
							<a-select-option :value="item.department_id" v-for="item in departments"
								:key="item.department_id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item> -->
				</a-form-model>
			</a-modal>
		</template>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			account_id: null,
			form: {

			},
			hospitals: [],
			hospitalselsect: [],
			departments: [],
			pagetotal: 0,
			ks_visible: false,
			bindObj: {},
			visible: false,
			confirmLoading: false,
			equipment: null,
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			// {
			// 	title: '用户类型',
			// 	scopedSlots: {
			// 		customRender: 'type'
			// 	}
			// },
			{
				title: '姓名',
				dataIndex: 'name'
			},
			{
				title: '联系电话',
				dataIndex: 'phone'
			},
			{
				title: '设备数',
				dataIndex: 'device_number'
			},
			// {
			// 	title: '所属医院',
			// 	scopedSlots: {
			// 		customRender: 'area'
			// 	}
			// },
			{
				title: '可提现金额',
				dataIndex: 'withdrawal_wait'
			},
			{
				title: '总奖励金额',
				dataIndex: 'withdrawal_all'
			},
			{
				title: '状态',
				scopedSlots: {
					customRender: 'stop_using'
				}
			},
			{
				title: '创建人',
				dataIndex: 'create_name'
			},
			{
				title: '操作',
				scopedSlots: {
					customRender: 'action'
				}
			}
			],
			data: [],
			selectedRowKeys: [],
			info_rules: {
				type: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}
				],
				name: [
					{
						required: true,
						message: '填写姓名',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '填写手机号',
						trigger: 'blur'
					}
				]
			},

			bindRules: {
				department_id: [
					{
						required: true,
						message: '请选择科室',
						trigger: 'blur'
					}
				],
			},
		}
	},
	created() {
		this.customer_id = this.$route.query.cid
		this.getList()
		// this.getHospitals()
	},
	methods: {
		stop(id) {
			this.$post(domain + '/admin/v1/reconciliation/accountStopUsing', { 'account_id': id }).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		// getHospitals() {
		// 	this.$post(domain + '/admin/v1/hospital/hospitalSelect', {}).then(res => {
		// 		if (res.code === 0) {
		// 			this.hospitals = res.data
		// 		} else {
		// 			this.$message.error(res.msg)
		// 		}
		// 	})
		// },
		handleCancel() {
			this.visible = false;
			this.initForm()
		},
		add() {
			this.visible = true;
		},
		toEdit(item) {
			this.form = { ...item };
			this.account_id = item.account_id;
			this.visible = true;
		},
		initForm() {
			this.form = {

			}
			console.log("清空后还有吗", this.form.hospital_id)
			this.account_id = null
		},
		submitInfo() {
			this.$refs.equipmentForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					let url = this.account_id ? 'accountEdit' : 'accountAdd'
					if (this.account_id) {
						this.form.account_id = this.account_id
					}
					this.$post("reconciliation/" + url, this.form)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.handleCancel()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		hospitalChange(name, obj) {
			this.$post(domain + '/admin/v1/hospital/departmentList', {
				hospital_id: obj.data.key,
				limit: 10000,
				page: 1,
			}).then(res => {
				if (res.code === 0) {
					this.departments = res.data.list
					this.form.department_id = null
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getDepartments(hospital_id) {
			this.$post(domain + '/admin/v1/hospital/departmentList', {
				hospital_id: hospital_id,
				limit: 10000,
				page: 1,
			}).then(res => {
				if (res.code === 0) {
					this.departments = res.data.list
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		getList() {
			this.$post(domain + '/admin/v1/reconciliation/accountList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList();
			console.log(query)
		},
		changeCheck(value) {
			if (value.length === 1) {
				this.query.is_on = value[0]
			} else {
				this.query.is_on = null
			}
			this.getList()
		},
		onSelectChange(selectedRowKeys) {
			console.log('选中中', selectedRowKeys)
			this.selectedRowKeys = selectedRowKeys
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
